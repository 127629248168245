.MuiBottomNavigationAction-root.Mui-selected {
    color: #ffffff!important;
}
#mobile-bottom-navigation{
    display: none;
}

@media (max-width: 1199px) {
    .breadcrumb-box{
        padding-left: 40px;
    }
    .breadcrumb-box h6{
        font-size: 0.8rem;
    }
}

@media (min-width: 1300px) {
    .setting .department select{
        width: 65%!important;
    }
    .setting .department button{
        width: 33%!important;
    }
}

@media (min-width: 768px) {
    #iconNavbarSidenav{
        margin-top: 18px;
        margin-left: 15px;
    }
}

@media (max-width: 767px) {
    
    #navbar .search-field{
        max-width: 300px;
        margin-left: 35px;
    }
}

@media (max-width: 575px) {
    .setting .department select{
        width: 100%!important;
        margin-bottom: 15px;
    }
    .setting .department button{
        width: 50%!important;
    }
    .setting .department_id{
        width: 100%!important;
        margin-bottom: 15px;
    }

    #mobile-bottom-navigation{
        display: block;
    }

    .main-content{
        padding-bottom: 56px;
    }
    #navbarBlur{
        display: none!important;
    }
    .mobile-topbar{
        display: block!important;
    }
    .mobile-topbar .card{
        z-index: 1;
    }
    .mobile-topbar .search-area{
        background: #ffffff;
        padding: 16px;
        border-radius: 1rem;
    }
    .mobile-topbar .search-result{
        width: 100%;
        margin-left: -16px;
        border-radius: 0rem 0rem 1rem 1rem;
    }

    /*
    #navbar ul{
        display: none!important;
    }    
    #navbar .search-field{
        width: 100%!important;
    }
    .g-sidenav-show .navbar:not(.sidenav).navbar-main .navbar-collapse,
    #navbar .d-flex{
        display: block !important;
    }
    */
}